// import lockImage from 'common/assets/image/appCreativeTwo/icons/lock.png';
import logoImage from 'common/assets/image/appCreativeTwo/logo.png';
import Box from 'common/components/Box';
// import Drawer from 'common/components/Drawer';
// import HamburgMenu from 'common/components/HamburgMenu';
// import Image from 'common/components/Image';
import NavbarWrapper from 'common/components/Navbar';
// import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Container from 'common/components/UI/Container';
import Logo from 'common/components/UIElements/Logo';
import {DrawerContext} from 'common/contexts/DrawerContext';
import {graphql, Link, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

const Navbar = ({navbarStyle, logoStyle, button, row, menuWrapper}) => {
    const {state, dispatch} = useContext(DrawerContext);

    // Toggle drawer
    const toggleHandler = () => {
        dispatch({
            type: 'TOGGLE',
        });
    };

    const data = useStaticQuery(graphql`
    query {
      appCreative2Json {
        menu_items_others {
          label
          path
          offset
        }
      }
    }
  `);

    const {appCreative2Json: menu_items_others} = data;

    return (
        <NavbarWrapper {...navbarStyle}>
            <Container width="1400px">
                <Box {...row}>
                    <Logo
                        href="/"
                        logoSrc={logoImage}
                        title="Fortune Mine Games"
                        logoStyle={logoStyle}
                        className="main-logo"
                    />
                    <Box {...menuWrapper} className="mainMenuWrapper">
                        <Link to="#" className="navbar_button navbar_button_one" {...button}>
                        </Link>
                        <Link to="/" className="navbar_button navbar_button_two" {...button}>
                            Home
                        </Link>
                        <Link to="https://www.linkedin.com/company/fortunemine-games/jobs/" target="_blank"
                              className="navbar_button navbar_button_two" {...button}>
                            Career
                        </Link>
                    </Box>
                </Box>
            </Container>
        </NavbarWrapper>
    );
};

Navbar.propTypes = {
    navbarStyle: PropTypes.object,
    logoStyle: PropTypes.object,
    button: PropTypes.object,
    row: PropTypes.object,
    menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
    navbarStyle: {
        className: 'app_creative_two_navbar',
        minHeight: '70px',
        display: 'block',
    },
    row: {
        flexBox: true,
        alignItems: 'center',
        width: '100%',
    },
    logoStyle: {
        maxWidth: ['148px', '148px'],
    },
    button: {},
    menuWrapper: {
        flexBox: true,
        alignItems: 'center',
    },
};

export default Navbar;
